// src/components/CallToActionSection.jsx
import React from 'react';
import { createCheckoutSession } from '../services/checkoutService';

import photoshopLogo from '../assets/photoshop.png';
import lightroomLogo from '../assets/photoshop-lightroom.png';
import illustratorLogo from '../assets/illustrator.png';
import afterEffectsLogo from '../assets/after-effects.png';
import premiereProLogo from '../assets/premiere-pro.png';
import xdLogo from '../assets/xd.png';
import finalcutLogo from '../assets/finalcut.png';
import canvaLogo from '../assets/canva.png';

const logos = [
    photoshopLogo,
    lightroomLogo,
    illustratorLogo,
    afterEffectsLogo,
    premiereProLogo,
    xdLogo,
    finalcutLogo,
    canvaLogo,
];

const CallToActionSection = () => {
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession("MEGA_BUNDLE");
            window.location.href = checkoutUrl;
        } catch (error) {
            alert('Failed to initiate checkout. Please try again.');
        }
    };

    return (
        <section className="py-16 bg-gray-200 text-gray-900">
            <div className="container mx-auto px-4 text-center">
                {/* Logos Section */}
                <h2 className="text-2xl font-bold text-gray-900 mb-4 lg:text-4xl -mt-28">And Much More!</h2>
                <p className="text-red-600 mb-2 lg:text-xl">Increase your earnings and efficiecy with time-saving tools and assets.</p>
                <p className="text-gray-800 mb-4 lg:text-xl">Join over 20,000 creators worldwide who rely on us to create stunning content, videos, and graphics every day.</p>

                {/* Display Logos */}
                <div className="flex flex-wrap justify-center items-center mt-6">
                    {logos.map((logo, index) => (
                        <img
                            key={index}
                            src={logo}
                            alt="Logo"
                            className="w-12 h-12 mx-2 md:w-16 md:h-16 lg:w-20 lg:h-20"
                        />
                    ))}
                </div>

                {/* Buy Now Banner Section */}
                <div className="mt-12 bg-red-600 text-white text-center py-6">
                    <p className="text-lg font-semibold mb-4">Don't delay, take advantage of this offer now!</p>
                    <button
                        onClick={handleBuyNow}
                        className="bg-white text-red-600 font-semibold px-6 py-3 rounded-full text-lg hover:bg-gray-100 hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out animate-bounce"
                    >
                        Buy Now
                    </button>
                </div>
            </div>
        </section>
    );
};

export default CallToActionSection;