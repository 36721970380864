import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

// Alphabet Group
import alphabetNoun from '../assets/kidCollection/alphabet/alphabet-noun.png';
import alphabetVowels from '../assets/kidCollection/alphabet/alphabet-vowels.png';
import alphabetEndingSound from '../assets/kidCollection/alphabet/alphabet-endingsound.png';
import alphabetStartingSound from '../assets/kidCollection/alphabet/alphabet-startingsound.png';
import alphabetVerbs from '../assets/kidCollection/alphabet/alphabet-verbs.png';

// Coloring Sheets Group
import coloringCutAndPaste from '../assets/kidCollection/coloringSheets/coloring-cutAndPaste.png';
import coloringFlashCard from '../assets/kidCollection/coloringSheets/coloring-flashCard.png';
import coloringFunColoring from '../assets/kidCollection/coloringSheets/coloring-funColoring.png';
import coloringMatchingGame from '../assets/kidCollection/coloringSheets/coloring-matchingGame.png';
import coloringSymetry from '../assets/kidCollection/coloringSheets/coloring-symetry.png';

// Flashcard Group
import flashcardActionVerb from '../assets/kidCollection/flashcard/flashcard-actionVerb.png';
import flashcardFruit from '../assets/kidCollection/flashcard/flashcard-fruit.png';
import flashcardHelper from '../assets/kidCollection/flashcard/flashcard-helper.png';
import flashcardMonth from '../assets/kidCollection/flashcard/flashcard-month.png';
import flashcardVegetable from '../assets/kidCollection/flashcard/flashcard-vegetable.png';

// Number Group
import numberAddition from '../assets/kidCollection/number/number-addition.png';
import numberBeforeAfter from '../assets/kidCollection/number/number-beforeAfter.png';
import numberCountAndWrite from '../assets/kidCollection/number/number-countAndWrite.png';
import numberHowMany from '../assets/kidCollection/number/number-howMany.png';
import numberScramble from '../assets/kidCollection/number/number-scramble.png';

// Pretend Play Group
import pretendPlayDentist from '../assets/kidCollection/pretendPlay/pretendPlay-dentist.png';
import pretendPlayGrocery from '../assets/kidCollection/pretendPlay/pretendPlay-grocery.png';
import pretendPlayIcecreamShop from '../assets/kidCollection/pretendPlay/pretendPlay-icecreamShop.png';
import pretendPlaySpaghetti from '../assets/kidCollection/pretendPlay/pretendPlay-spaghetti.png';
import pretendPlaySportTraining from '../assets/kidCollection/pretendPlay/pretendPlay-sportTraining.png';

// Shapes Group
import shapeColorTheShape from '../assets/kidCollection/shapes/shape-colorTheShape.png';
import shapeDifferent from '../assets/kidCollection/shapes/shape-different.png';
import shapeLearningShape from '../assets/kidCollection/shapes/shape-learningShape.png';
import shapeMatchTheShape from '../assets/kidCollection/shapes/shape-matchTheShape.png';
import shapeSide from '../assets/kidCollection/shapes/shape-side.png';

// Sight Word Group
import sightWordColoringWorksheet from '../assets/kidCollection/sightWord/sightWord-coloringWorksheet.png';
import sightWordFindTheColor from '../assets/kidCollection/sightWord/sightWord-findTheColor.png';
import sightWordMatching from '../assets/kidCollection/sightWord/sightWord-matching.png';
import sightWordReadAndCircle from '../assets/kidCollection/sightWord/sightWord-readAndCircle.png';
import sightWordTracingSheet from '../assets/kidCollection/sightWord/sightWord-tracingSheet.png';

// Tracing Group
import tracingTraceAndColor from '../assets/kidCollection/tracing/tracing-traceAndColor.png';
import tracingTraceTheLine from '../assets/kidCollection/tracing/tracing-traceTheLine.png';
import tracingTraceThePattern from '../assets/kidCollection/tracing/tracing-traceThePattern.png';
import tracingTraceTheShape from '../assets/kidCollection/tracing/tracing-traceTheShape.png';

const KidsCapsuleTabs = () => {
    const tabs = [
        'Alphabet',
        'Coloring Sheets',
        'Numbers',
        'Pretend Play',
        'Flashcard',
        'Shapes',
        'Sight Words',
        'Tracing',
    ];

    const [activeTab, setActiveTab] = useState(tabs[0]); // Default to the first tab
    const [currentSlide, setCurrentSlide] = useState(1); // Start at the first real slide
    const sliderRef = useRef(null);

    const slides = useMemo(() => {
        const allSlides = {
            'Alphabet': [alphabetEndingSound, alphabetNoun, alphabetStartingSound, alphabetVerbs, alphabetVowels],
            'Coloring Sheets': [coloringCutAndPaste, coloringFlashCard, coloringFunColoring, coloringMatchingGame, coloringSymetry],
            'Numbers': [numberAddition, numberBeforeAfter, numberCountAndWrite, numberHowMany, numberScramble],
            'Pretend Play': [pretendPlayDentist, pretendPlayGrocery, pretendPlayIcecreamShop, pretendPlaySpaghetti, pretendPlaySportTraining],
            'Flashcard': [flashcardActionVerb, flashcardFruit, flashcardHelper, flashcardMonth, flashcardVegetable],
            'Shapes': [shapeColorTheShape, shapeDifferent, shapeLearningShape, shapeMatchTheShape, shapeSide],
            'Sight Words': [sightWordColoringWorksheet, sightWordFindTheColor, sightWordMatching, sightWordReadAndCircle, sightWordTracingSheet],
            'Tracing': [tracingTraceAndColor, tracingTraceTheLine, tracingTraceThePattern, tracingTraceTheShape],
        };

        // Add duplicate slides for seamless looping
        for (const tab in allSlides) {
            const slides = allSlides[tab];
            allSlides[tab] = [slides[slides.length - 1], ...slides, slides[0]]; // Add last and first slides as duplicates
        }
        return allSlides;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const activeSlides = slides[activeTab] || [];

    useEffect(() => {
        setCurrentSlide(1); // Reset to the first real slide on tab change
    }, [activeTab]);

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.style.transition = 'transform 0.5s ease-in-out';
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.style.transition = 'transform 0.5s ease-in-out';
            setCurrentSlide((prev) => prev - 1);
        }
    };

    useEffect(() => {
        if (currentSlide === activeSlides.length - 1) {
            setTimeout(() => {
                sliderRef.current.style.transition = 'none';
                setCurrentSlide(1);
            }, 500); // Match the transition duration
        } else if (currentSlide === 0) {
            setTimeout(() => {
                sliderRef.current.style.transition = 'none';
                setCurrentSlide(activeSlides.length - 2);
            }, 500);
        }
    }, [currentSlide, activeSlides]);

    useEffect(() => {
        const preloadImages = (images) => {
            images.forEach((src) => {
                const img = new Image();
                img.src = src;
            });
        };
        Object.values(slides).forEach(preloadImages);
    }, [slides]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            if (eventData.event) {
                if (eventData.event.type === 'mousemove') {
                    if (eventData.event.button !== 0) {
                        // Ignore middle or right mouse button swipes
                        return;
                    }
                    // Prevent default behavior for left mouse drag
                    eventData.event.preventDefault();
                }
            }
            nextSlide();
        },
        onSwipedRight: (eventData) => {
            if (eventData.event) {
                if (eventData.event.type === 'mousemove') {
                    if (eventData.event.button !== 0) {
                        // Ignore middle or right mouse button swipes
                        return;
                    }
                    // Prevent default behavior for left mouse drag
                    eventData.event.preventDefault();
                }
            }
            prevSlide();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Enable mouse swiping for desktops
    });

    // Descriptions for each tab
    const descriptions = {
        'Alphabet': (
            <>
                <p>
                    The <strong>Alphabet</strong> tab introduces children to the fundamentals of language.
                    This section focuses on building a strong foundation by engaging kids with
                    interactive and fun activities.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-pink-500 font-bold">🔡 Ending Sounds:</span> Identify and understand the ending sounds of words.
                    </li>
                    <li>
                        <span className="text-pink-500 font-bold">🔡 Nouns:</span> Learn about nouns with illustrative examples and engaging activities.
                    </li>
                    <li>
                        <span className="text-pink-500 font-bold">🔡 Starting Sounds:</span> Emphasize the beginning sounds of words to enhance phonetic awareness.
                    </li>
                    <li>
                        <span className="text-pink-500 font-bold">🔡 Verbs:</span> Discover action words and their usage through colorful images.
                    </li>
                    <li>
                        <span className="text-pink-500 font-bold">🔡 Vowels:</span> Understand vowels and their role in forming words, with interactive challenges.
                    </li>
                </ul>
            </>
        ),
        'Coloring Sheets': (
            <>
                <p>
                    <strong>Coloring Sheets</strong> are a delightful way to incorporate art into learning.
                    They help children develop fine motor skills and encourage them to explore their creativity.
                    By experimenting with different colors, shapes, and patterns, kids improve their hand–eye
                    coordination while expressing themselves artistically.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-orange-500 font-bold">🎨 Cut-and-Paste Activities:</span>
                        Foster creativity and improve scissor control.
                    </li>
                    <li>
                        <span className="text-orange-500 font-bold">🎨 Flashcard Coloring:</span>
                        Reinforce vocabulary through color-based memory aids.
                    </li>
                    <li>
                        <span className="text-orange-500 font-bold">🎨 Fun &amp; Engaging Games:</span>
                        Combine coloring with playful challenges to keep kids motivated.
                    </li>
                    <li>
                        <span className="text-orange-500 font-bold">🎨 Matching Games:</span>
                        Train visual discrimination by pairing up related pictures.
                    </li>
                    <li>
                        <span className="text-orange-500 font-bold">🎨 Symmetry Exercises:</span>
                        Build spatial awareness and appreciation for balanced design.
                    </li>
                </ul>
            </>
        ),
        'Numbers': (
            <>
                <p>
                    <strong>Numbers</strong> form the basis of early math skills. Through engaging lessons
                    and interactive visuals, children learn to count, order, and manipulate numbers in
                    real-world scenarios.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-teal-600 font-bold">🔢 Addition and Subtraction:</span>
                        Practice combining and separating groups of objects.
                    </li>
                    <li>
                        <span className="text-teal-600 font-bold">🔢 Before and After Numbers:</span>
                        Understand numeric sequences and logical progression.
                    </li>
                    <li>
                        <span className="text-teal-600 font-bold">🔢 Count and Write:</span>
                        Reinforce number formation alongside counting activities.
                    </li>
                    <li>
                        <span className="text-teal-600 font-bold">🔢 How Many Games:</span>
                        Boost quick-thinking by identifying quantities on the spot.
                    </li>
                    <li>
                        <span className="text-teal-600 font-bold">🔢 Number Scrambles:</span>
                        Sharpen problem-solving skills by putting numbers in the right order.
                    </li>
                </ul>
            </>
        ),
        'Pretend Play': (
            <>
                <p>
                    <strong>Pretend Play</strong> invites children into imaginative worlds where they
                    can explore different roles and scenarios. This helps build social skills, empathy,
                    and cognitive flexibility, all while having fun.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-purple-600 font-bold">🎭 Playing Dentist:</span>
                        Explore health and hygiene through playful role-play.
                    </li>
                    <li>
                        <span className="text-purple-600 font-bold">🎭 Grocery Shopping:</span>
                        Introduce budgeting and decision-making in a realistic setting.
                    </li>
                    <li>
                        <span className="text-purple-600 font-bold">🎭 Ice Cream Shop Fun:</span>
                        Spark creativity in “making” and serving delightful treats.
                    </li>
                    <li>
                        <span className="text-purple-600 font-bold">🎭 Cooking Spaghetti:</span>
                        Encourage teamwork and problem-solving in a pretend kitchen.
                    </li>
                    <li>
                        <span className="text-purple-600 font-bold">🎭 Sports Training:</span>
                        Promote physical activity and coordination through simulated practice.
                    </li>
                </ul>
            </>
        ),
        'Flashcard': (
            <>
                <p>
                    <strong>Flashcards</strong> are a timeless tool for boosting vocabulary, sharpening
                    memory, and enhancing recognition skills. Each set of flashcards focuses on a specific
                    theme, making it easier for kids to group and remember concepts.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-blue-500 font-bold">💡 Action Verbs:</span>
                        Enrich language by connecting words with dynamic movements.
                    </li>
                    <li>
                        <span className="text-blue-500 font-bold">💡 Fruits:</span>
                        Encourage healthy eating habits with vibrant illustrations.
                    </li>
                    <li>
                        <span className="text-blue-500 font-bold">💡 Helpers:</span>
                        Celebrate everyday heroes and spark curiosity about various professions.
                    </li>
                    <li>
                        <span className="text-blue-500 font-bold">💡 Months:</span>
                        Introduce children to calendar basics and seasonal changes.
                    </li>
                    <li>
                        <span className="text-blue-500 font-bold">💡 Vegetables:</span>
                        Promote nutritious choices while expanding vocabulary.
                    </li>
                </ul>
            </>
        ),
        'Shapes': (
            <>
                <p>
                    <strong>Shapes</strong> give children a simple way to understand geometry, categorization,
                    and visual order. Identifying and comparing shapes lays groundwork for more complex math
                    and spatial reasoning.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-pink-600 font-bold">🔺 Color the Shape:</span>
                        Reinforce shape recognition through hands-on coloring tasks.
                    </li>
                    <li>
                        <span className="text-pink-600 font-bold">🔺 Find the Different Shape:</span>
                        Exercise observational skills by spotting the outlier.
                    </li>
                    <li>
                        <span className="text-pink-600 font-bold">🔺 Learning Shape Basics:</span>
                        Dive into circles, squares, triangles, and more.
                    </li>
                    <li>
                        <span className="text-pink-600 font-bold">🔺 Shape Matching:</span>
                        Develop problem-solving through matching similar shapes.
                    </li>
                    <li>
                        <span className="text-pink-600 font-bold">🔺 Understanding Sides:</span>
                        Bridge the gap to geometry with lessons on edges and corners.
                    </li>
                </ul>
            </>
        ),
        'Sight Words': (
            <>
                <p>
                    <strong>Sight Words</strong> empower young learners to read faster and more confidently
                    by memorizing high-frequency words. Immediate recognition reduces decoding time and
                    allows them to focus on comprehension.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-green-600 font-bold">👀 Coloring Worksheets:</span>
                        Blend art with word practice for a well-rounded experience.
                    </li>
                    <li>
                        <span className="text-green-600 font-bold">👀 Find the Color Activity:</span>
                        Boost color recognition and word familiarity together.
                    </li>
                    <li>
                        <span className="text-green-600 font-bold">👀 Word Matching:</span>
                        Reinforce word-image associations for improved recall.
                    </li>
                    <li>
                        <span className="text-green-600 font-bold">👀 Read-and-Circle Games:</span>
                        Make reading interactive and fun.
                    </li>
                    <li>
                        <span className="text-green-600 font-bold">👀 Tracing Worksheets:</span>
                        Combine handwriting practice with word recognition.
                    </li>
                </ul>
            </>
        ),
        'Tracing': (
            <>
                <p>
                    <strong>Tracing</strong> activities strengthen handwriting, fine motor control, and
                    the ability to follow visual guidelines. Consistent practice helps children master
                    letter, number, and shape formation.
                </p>
                <ul className="list-disc ml-4 mt-2 text-left space-y-1">
                    <li>
                        <span className="text-indigo-600 font-bold">✏️ Trace and Color:</span>
                        Practice pen control while enjoying playful images.
                    </li>
                    <li>
                        <span className="text-indigo-600 font-bold">✏️ Trace the Line:</span>
                        Hone steady hand movements and line accuracy.
                    </li>
                    <li>
                        <span className="text-indigo-600 font-bold">✏️ Trace the Pattern:</span>
                        Develop pattern recognition and repetitive motor skills.
                    </li>
                    <li>
                        <span className="text-indigo-600 font-bold">✏️ Trace the Shape:</span>
                        Introduce geometric concepts through guided tracing.
                    </li>
                </ul>
            </>
        ),
    };

    return (
        <div className="kids-capsule-tabs bg-white py-6">
            {/* Section Header */}
            <div className="section-header text-center mb-6">
                <h2 className="text-3xl font-bold text-gray-600">
                    Highlights from Our Collection
                </h2>
                <div className="w-24 h-1 mx-auto bg-blue-500 mt-2 rounded"></div>
                <p className="text-sm text-gray-500 mt-2">
                    Explore our diverse library of engaging and educational activities!
                </p>
            </div>

            {/* Tabs */}
            <div className="max-w-6xl mx-auto px-4">
                <div className="tabs-container flex overflow-x-auto justify-between space-x-4">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`capsule-tab px-6 py-2 rounded-full border transition-all duration-300 ${activeTab === tab
                                ? 'bg-blue-500 text-white border-blue-500'
                                : 'bg-white text-blue-500 border-blue-300 hover:bg-blue-200'
                                }`}
                        >
                            {tab}
                        </button>
                    ))}
                </div>

                {/* Content Section */}
                <div className="content-container mt-10 flex flex-col md:flex-row items-start md:items-center md:space-x-8">
                    <div className="text-description flex-1 md:text-left text-center md:pr-4">
                        <h3 className="text-2xl font-semibold text-blue-700">{activeTab}</h3>
                        <div className="text-gray-600 mt-4 leading-relaxed">
                            {/* If tab description exists, render it; otherwise, show a fallback */}
                            {descriptions[activeTab] || (
                                <p>
                                    A brief description of <strong>{activeTab}</strong> will appear here.
                                </p>
                            )}
                        </div>
                    </div>

                    {/* Image Slider */}
                    {activeSlides.length > 0 && (
                        <div
                            className="image-slider flex-1 mt-6 md:mt-0 relative bg-white rounded-lg overflow-hidden shadow-xl"
                            {...swipeHandlers}
                        >
                            <div
                                ref={sliderRef}
                                className="w-full h-full flex transition-transform duration-500"
                                style={{
                                    transform: `translateX(-${currentSlide * 100}%)`,
                                }}
                            >
                                {activeSlides.map((slide, index) => (
                                    <img
                                        key={index}
                                        src={slide}
                                        alt={`${activeTab} Slide ${index}`}
                                        className="w-full h-full object-contain"
                                        style={{ pointerEvents: 'none', userSelect: 'none' }}
                                    />
                                ))}
                            </div>
                            {/* Navigation Arrows */}
                            <button
                                onClick={prevSlide}
                                className="absolute top-1/2 left-2 transform -translate-y-1/2 text-yellow-500 text-6xl font-bold focus:outline-none z-10"
                            >
                                &#8249;
                            </button>
                            <button
                                onClick={nextSlide}
                                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-yellow-500 text-6xl font-bold focus:outline-none z-10"
                            >
                                &#8250;
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default KidsCapsuleTabs;