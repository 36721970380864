import React from "react";
import contentPreview from "../assets/kidCollection/kid_product_overview.png";
import { createCheckoutSession } from "../services/checkoutService";

const CheckoutSection = () => {
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession("KID_BUNDLE");
            window.location.href = checkoutUrl;
        } catch (error) {
            alert("Failed to initiate checkout. Please try again.");
        }
    };

    return (
        <div className="checkout-section bg-white py-20 text-center -mt-20 lg:mt-0">
            {/* Title */}
            <h2 className="text-4xl font-bold text-gray-800 mb-2">
                Check out the Amazing Pricing
            </h2>

            <p className="text-xl text-gray-600 mb-8">
                Massive <span className="text-green-500 font-bold">Discount</span> Available
            </p>
            <div className="w-48 h-1 bg-blue-500 mx-auto mb-6"></div>

            {/* Pricing & Content Preview Container */}
            <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0 lg:space-x-0">
                {/* Pricing Section */}
                <div className="pricing-box border border-gray-300 p-8 rounded-lg shadow-lg max-w-md text-left lg:ml-36">
                    <p className="text-sm font-bold text-gray-600 uppercase">Standard - Single Payment</p>
                    <div className="text-5xl font-bold text-gray-900 mt-2 mb-1">
                        $19 <span className="text-red-500 text-2xl line-through">Was $67</span>
                    </div>
                    <hr className="my-4 border-gray-300" />

                    {/* Features Checklist */}
                    <ul className="text-gray-700 space-y-3">
                        <li>✔ <strong>50</strong> Editable Videos & Quizzes</li>
                        <li>✔ Each video includes voiceover & animation.</li>
                        <li>✔ <strong>Unlimited Private Label Rights:</strong> Rebrand, claim authorship, and resell Kidd Products for 100% profit.</li>
                        <li>✔ <strong>Versatile</strong> video templates, easy to edit with a free drag & drop editor.</li>
                        <li>✔ <strong>Instant</strong> Access</li>
                        <li>✔ <strong>One time</strong> payment</li>
                        <li>✔ <strong>1,000+ Pre-School Materials</strong></li>
                        <li>✔ Get a <strong>ready-to-go</strong> digital product business in the hottest niche</li>
                    </ul>

                    {/* Buy Now Button with real functionality */}
                    <button
                        onClick={handleBuyNow} // Trigger checkout function to Stripe
                        className="mt-6 w-full py-3 bg-pink-200 text-pink-800 font-semibold rounded-lg shadow-md transition duration-300 hover:bg-pink-300"
                    >
                        Buy Now
                    </button>
                </div>

                {/* Content Preview Section */}
                <div className="content-preview flex-1 flex flex-col items-center">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4 lg:mr-24">Instant Access to All Content</h3>
                    <img
                        src={contentPreview}
                        alt="Instantly Access All Content"
                        className="rounded-lg shadow-lg w-72 lg:w-80 mt-4 lg:mr-24"
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckoutSection;
