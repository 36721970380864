import React from 'react';
import SectionHeader from './common/SectionHeader';
import { FaCheck } from 'react-icons/fa';
import { createCheckoutSession } from '../services/checkoutService';

const BuyerRights = () => {
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession("MEGA_BUNDLE");
            window.location.href = checkoutUrl;
        } catch (error) {
            alert('Failed to initiate checkout. Please try again.');
        }
    };

    const rights = [
        'Complete Bundle',
        'Instant access',
        'Lifetime access',
        'Commercial use',
        'One-time payment',
        'Written rights to sell this bundle',
    ];

    return (
        <section className="py-16 bg-gray-900 text-white">
            <div className="container mx-auto px-4 text-center">
                <SectionHeader title="What You Get with This Bundle" customClass="text-white" />
                <div className="relative mt-8 bg-gray-800 p-6 md:p-10 lg:p-12 rounded-lg shadow-md max-w-md md:max-w-lg lg:max-w-2xl mx-auto">
                    {/* Save Badge with Flash Effect */}
                    <div className="absolute top-0 right-0 mt-4 mr-4 bg-red-600 text-white px-2 md:px-4 py-1 rounded-full text-sm font-bold animate-flash">
                        Save 80%
                    </div>
                    {/* Pricing Section */}
                    <h2 className="text-3xl lg:text-5xl font-bold text-yellow-400 mb-4">
                        $34 <span className="line-through text-gray-400">$279</span>
                    </h2>
                    {/* Buy Now Button */}
                    <button
                        onClick={handleBuyNow}
                        className="w-full lg:w-1/1 px-8 py-3 lg:py-4 bg-red-600 text-lg lg:text-2xl font-semibold rounded-full hover:bg-red-700 transform hover:scale-105 transition duration-300"
                    >
                        Buy Now
                    </button>
                    {/* Secure Checkout Message */}
                    <p className="mt-4 text-sm lg:text-base text-gray-400">
                        Secure checkout with <span className="text-blue-500 font-semibold">Stripe</span>
                    </p>
                    {/* Feature List */}
                    <ul className="mt-6 lg:mt-8 space-y-4 text-left text-base lg:text-lg">
                        {rights.map((right, index) => (
                            <li key={index} className="flex items-center space-x-2">
                                <div className="flex items-center bg-gray-700 text-white px-4 py-2 rounded-full">
                                    <FaCheck className="text-blue-400 mr-2" />
                                    <span>{right}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default BuyerRights;
