// src/components/BenefitsSection.jsx

import React from "react";

const benefitsData = [
    {
        title: "AVAILABLE TO ANYONE WITH ACCESS TO THE INTERNET",
        description:
            "No matter where you are in the world you can build a business and work from anywhere selling digital products.",
    },
    {
        title: "PERFECT FOR STAY AT HOME PARENTS",
        description:
            "Parents can work from home adding extra income to pay for essentials. The kids Edutainment Market is HUGE.",
    },
    {
        title: "INCREDIBLY LOW COST ENTRY",
        description:
            "Never before in the history of the world has there been an opportunity to build a profitable business with such a low cost of entry.",
    },
    {
        title: "YOU ARE IN CONTROL OF YOUR OWN DESTINY",
        description:
            "Sell the videos on any marketplace or even set up a dedicated YouTube Channel.",
    },
];

const BenefitsSection = () => {
    return (
        <div className="benefits-section bg-white py-16 -mt-8">
            <div className="max-w-7xl mx-auto px-4 text-center mb-8">
                <h2 className="text-4xl font-bold text-gray-800">
                    Everyone can <span className="text-teal-500">benefit with us</span>
                </h2>
            </div>
            <div className="w-48 h-1 bg-blue-500 mx-auto mb-6"></div>

            {/* Benefits List */}
            <div className="benefits-list flex overflow-x-auto space-x-4 px-4 lg:justify-center">
                {benefitsData.map((benefit, index) => (
                    <div
                        key={index}
                        className="benefit-card bg-teal-500 text-white p-6 rounded-xl shadow-lg flex-shrink-0 w-64 sm:w-80"
                    >
                        <h3 className="text-sm font-semibold uppercase mb-2">Benefit</h3>
                        <h4 className="text-lg font-bold mb-4">{benefit.title}</h4>
                        <p className="text-sm">{benefit.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BenefitsSection;