// src/components/KidsBanner.jsx
import React from 'react';
import kidsImage from '../assets/kids_collection_banner.png';

const KidsBanner = () => {
    return (
        <div className="kids-banner flex items-center justify-center bg-blue-100 py-10">
            <img
                src={kidsImage}
                alt="Kids Collection Banner"
                className="max-w-full h-auto rounded-md shadow-lg"
            />
        </div>
    );
};

export default KidsBanner;