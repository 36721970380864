import React from "react";
import showcaseVideo from "../assets/kidCollection/kid_video.mp4";

const VideoShowcase = () => {
    return (
        <div className="video-showcase-section bg-white py-20 text-center -mt-16 lg:mt-0">
            {/* Title */}
            <h2 className="text-4xl font-bold text-gray-800 mb-6">
                Generate Ad Revenue Via YouTube
            </h2>
            <div className="w-48 h-1 bg-blue-500 mx-auto mb-6"></div>

            {/* Video Embed */}
            <div className="max-w-4xl mx-auto">
                <video className="w-full rounded-lg shadow-lg" controls>
                    <source src={showcaseVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default VideoShowcase;