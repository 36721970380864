// /src/services/checkoutService.js

const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/checkout`;

/**
 * Function to create a Stripe Checkout Session by calling the backend API.
 * @param {string} productType - The type of product ("MEGA_BUNDLE" or "KID_BUNDLE").
 * @returns {Promise<string>} - The Stripe Checkout URL for redirect
 */
export const createCheckoutSession = async (productType) => {
    try {
        const response = await fetch(`${apiBaseUrl}/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productType }) // Send product type to backend
        });

        if (!response.ok) {
            throw new Error('Failed to create checkout session');
        }

        const data = await response.json();
        return data.url; // The Stripe Checkout URL
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};

/**
 * Function to get the success status from the backend using session_id.
 * @param {string} sessionId - The Stripe session ID to verify the payment status.
 * @returns {Promise<object>} - The status and message from the backend.
 */
export const getSuccessStatus = async (sessionId) => {
    try {
        const response = await fetch(`${apiBaseUrl}/success?session_id=${sessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch success status');
        }

        return await response.json(); // Backend response containingj status and message
    } catch (error) {
        console.error('Error fetching success status:', error);
        throw error;
    }
};

/**
 * Function to handle cancellation status. (Currently for frontend use)
 * @returns {object} - Hardcoded cancellation status and message.
 */
export const getCancelStatus = async () => {
    return { status: 'canceled', message: 'Your payment was canceled. Feel free to retry anytime.' };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { createCheckoutSession, getSuccessStatus, getCancelStatus };