// src/pages/KidsCollectionPage.jsx
import React from 'react';
import KidsBanner from '../components/KidsBanner';
import KidsCapsuleTabs from '../components/KidsCapsuleTabs';
import BonusVideoSection from '../components/BonusVideoSection';
import BenefitsSection from '../components/BenefitsSection';
import VideoShowcase from '../components/VideoShowcase';
import CheckoutSection from '../components/KidsCheckoutSection';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BackToTop from '../components/BackToTop';

const KidsCollectionPage = () => {
    return (
        <div className="kids-collection-page">
            {/* Header Section */}
            <Header />

            {/* Banner Section */}
            <KidsBanner />

            {/* Capsule Tabs Section */}
            <KidsCapsuleTabs />

            {/* Bonus Video Section */}
            <BonusVideoSection />

            {/* Benefits Section */}
            <BenefitsSection />

            {/* Video Showcase Section */}
            <VideoShowcase />

            {/* Checkout Section */}
            <CheckoutSection />

            {/* Footer Section */}
            <Footer />

            {/* Back to Top */}
            <BackToTop />
        </div>
    );
};

export default KidsCollectionPage;